var Aneox;
(function(Aneox) {
    'use strict';

    var App = (function() {

        var self;

        var isTouchDevice;

        var currentLocale;
        var currentNode;
        var currentController;
        var loaded = false;

        var tl = new TimelineMax();

        function App(fonts, locale, router, analytics, homeController, legalController) {
            self = this;

            self.fonts = fonts;
            self.locale = locale;
            self.router = router;
            self.analytics = analytics;
            self.controllers = {
                home: homeController,
                legal: legalController
            };
            
            //console.log('app');

            var md = new MobileDetect(window.navigator.userAgent);
            Aneox.Data.Config.runtime = {
                isTouchDevice: 'ontouchstart' in document.documentElement,
                mobile: md.mobile(),
                supportsScrollEvents: !md.mobile() || (md.is('iOS') && md.is('Safari') && md.version('Safari') > 8)
            };

            lazySizesConfig.preloadAfterLoad = true;


            self.router.bind('pageChange', function(path, node, nodeLocale) {
                //console.log('page change');
                //console.log(nodeLocale);
                self.locale.setLocale(nodeLocale.locale);
                if (_.get(currentNode, 'id') === node.id) return;

                var rootNodeId, controllerId;

                // Enter/Leave animations
                if (_.get(currentNode, 'id') !== node.id) {
                    switch (_.get(currentNode, 'type')) {
                        case 'basic-page':
                            self.controllers[currentNode.id].leave();
                            break;
                        case 'wine-page':
                            if (node.type === 'wine-page') break;
                            self.controllers.wines.leave();
                            break;
                    }

                    switch (node.type) {
                        case 'wine-page':
                            if (_.get(currentNode, 'type') !== 'wine-page') self.controllers.wines.enter(node.id);
                            else self.controllers.wines.swap(node.id);
                            rootNodeId = 'wines';
                            controllerId = 'wines';
                            break;
                        default:
                            // if first page load, defer enter (until after splashscreen anims)
                            if (currentNode) {
                                self.controllers[node.id].enter();
                            }
                            rootNodeId = node.id;
                            controllerId = node.id;
                            break;
                    }
                }

                currentNode = node;
                currentController = self.controllers[controllerId];
            });

            self.locale.bind('localeChange', function(locale) {
                //console.log('localeChange: '+locale);
                if (currentLocale === locale) return;

                // Update active language menu item
                // $('#main-menu .language a').removeClass('active');
                // $('#main-menu .language a[router-locale="' + locale + '"]').addClass('active');

                currentLocale = locale;

                setTimeout(handleWindowResize);
            });

            // Window resize handling
            var handleWindowResize = function() {
                var windowHeight = $(window).height();

                _.forEach($('[screen-height]'), function(element) {
                    var heightElement = $(element);
                    var topMargin = _.parseInt(heightElement.css('margin-top'));
                    var bottomMargin = _.parseInt(heightElement.css('margin-top'));
                    var height = windowHeight - topMargin - bottomMargin;

                    heightElement.innerHeight(height);
                });

                if (currentController && currentController.handleWindowResize) currentController.handleWindowResize.call(currentController);
            };

            var throttledWindowResizeHandler = _.throttle(handleWindowResize, 500, { leading: false, trailing: true });
            $(window).resize(throttledWindowResizeHandler);
            Visibility.change(throttledWindowResizeHandler);
            self.fonts.bind('fontsActive', handleWindowResize);
            handleWindowResize();

            // Detect browser language
            setTimeout(function() {
                var language = (navigator.language || navigator.userLanguage).slice(0, 2);
                if (_.includes(Aneox.Data.Config.locales, language)) {
                    //self.locale.setLocale(language);
                    self.router.setPath('/'+language);
                } else {
                    self.router.setPath('/en');
                    //self.locale.setLocale('en');
                }
                
                //self.router.reparseAnchors();
                
            }, 0);

            tl.set('#logo-splash', {
                opacity: 0
            })
            .to('#logo-splash', .8,{opacity: 1, delay: .5})
            .to('#logo-splash', .8, {
                delay: 1,
                scale: 0.8,
                y: '-50px',
                /*scale: (13/15),
                rotation: .001,*/
                ease: Power2.easeOut
            });

            if (Cookies.get('splashscreen') !== 'true') {
                var now = new Date();
                now.setFullYear(now.getFullYear() - 18);
                var nowText = now.toISOString().substring(0, 10).split('-');
                $('#splashscreen-date').text(nowText[2] + '/' + nowText[1] + '/' + nowText[0]);
                tl.set('#splashscreen-agecheck', {
                    display: 'block'
                })
                .to('#splashscreen-agecheck', .8, {
                    opacity: 1,
                    rotation: .001
                });
            } else {
                showLoader();
            }

            var queue = new createjs.LoadQueue(true);
            var mapImg = 'assets/fond_carte_web-90.jpg'
            queue.loadFile(mapImg);
            queue.on('progress', function(event) {
                $('#load-percentage').text((event.progress * 100).toFixed(0));
            });
            queue.on('complete', fadeScreen);
            function fadeScreen() {
                loaded = true;
                if ($('#preloader-text').is(':visible')) {
                    displayScreen();
                } else {
                    setTimeout(fadeScreen, 100);
                }
            }

        };
        
        function displayScreen(){
            if (self.router.getNode().id === 'legal') {
                       
                tl.to('#splashscreen', .8, {
                    opacity: 0,
                    rotation: .001
                })
                .set('#splashscreen', {
                    display: 'none',
                    onComplete: currentController.enter
                })
            } else {

                tl.to('#splashscreen', .8, {
                    opacity: 0,
                    rotation: .001
                })
                .set('#splashscreen', {
                    display: 'none',
                    onComplete: currentController.enter
                });
            }
        }

        App.prototype.acceptSplash = function() {
            Cookies.set('splashscreen', 'true');
            showLoader();
        };

        App.prototype.refuseSplash = function() {
            window.location.href = 'https://google.fr';
        };

        

        function showLoader() {
            if(loaded == true){
                displayScreen();
            }else{
                tl.set('#splashscreen-agecheck', {
                    display: 'none'
                })
                .set('#preloader-text', {
                    display: 'block'
                })
                .to('#preloader-text', .8, {
                    opacity: 1
                });
            }
            
        }
        
        return App;
        
    })();

    Aneox.App = App;
})(Aneox || (Aneox = {}));