var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Home = (function() {

            var self;
            var currentZoomLevel = null;

            function Home(animations) {
                self = this;
                self.timeline = animations.timeline;
            }

            Home.prototype.enter = function() {
                self.timeline.set('#home, #home section', { display: 'block', opacity: 0 });
                self.timeline.call(self.handleWindowResize);
                self.timeline.set('#home-mast', { opacity: 1 });
                

                // self.timeline.fromTo('#home-mast .wave', 1, { width: 0, x: -150, opacity: 0 }, { width: 300, x: 0, opacity: 1 });
                // self.timeline.fromTo('#home-mast h1', 1, { opacity: 0, y: '100%' }, { opacity: 1, y: '0%' }, '-=0.5');
                // self.timeline.fromTo('#home-mast p', 1, { opacity: 0, y: '-100%' }, { opacity: 1, y: '0%' }, '-=0.5');
                // self.timeline.fromTo('#home-mast a', 1, { opacity: 0, y: '-100%' }, { opacity: 1, y: '0%' }, '-=0.5');

                self.showMap();
                
            };

            Home.prototype.leave = function() {
                self.timeline.to('#home', 0.5, { display: 'none', opacity: 0 });
            };

            Home.prototype.handleWindowResize = function() {
            };

            //show map (1st test visibility)
            Home.prototype.showMap = function() {
                

                self.timeline.call(function() {
                    //zoom reset 
                    if (self.panZoom) self.panZoom.panzoom('destroy');
                    self.panZoom = undefined;
                    $('#map-svg').empty();

                    //Map constructor (initialization, load and appending svg file)
                    self.snap = Snap('#map-svg');
                    Snap.load((rootPath || '') + 'assets/cartographie_infos.svg', function(svg) {
                        self.snap.append(svg); //ready for work 

                        //Set background image
                        $('#map-svg svg').css('background-image', 'url(assets/fond_carte_web-90.jpg)');

                        //Add flashing dot over Saint-Aubin
                        var pointerArea = Snap();
                        var circle = pointerArea.circle(1241, 332, 50);
                        circle.attr({
                            class: "point-saint-aubin",
                            //fill: "r(0.5, 0.5, 0.9)#fff-rgba(255,255,255,0):50-rgba(255,255,255,0)"
                            fill: "r(0.5, 0.5, 0.7)rgba(241,234,226,0.7)-rgba(241,234,226,0):50-rgba(241,234,226,0)"
                        });
                        circle.hover(function() {
                            circle.transform(Snap.matrix(1,0,0,1,0,0).scale(1.2, 1.2, 1241, 332).toTransformString());
                        }, function() {
                            circle.transform(Snap.matrix(1,0,0,1,0,0).toTransformString());
                        });
                            
                        Snap('#map-svg svg').append(circle);
                        $('.point-saint-aubin').click(self.moreInfo);

                        //panZoom constructor (set min and max scale with contain)
                        self.panZoom = $('#map-svg svg').panzoom({
                            minScale: 1,
                            maxScale: 10,
                            contain: 'invert'
                        });

                        //Add Listener panzoomzoom on self.panzoom
                        self.panZoom.on('panzoomzoom',function(e,panzoom,scale){

                            currentZoomLevel = scale;
                            
                            if(currentZoomLevel >= 3 && $(".logo-wrapper").is(':visible')){
                                TweenMax.to(".logo-wrapper", .5, {opacity:0, display: "none"});
                            }else if(currentZoomLevel <= 3 && !$(".logo-wrapper").is(':visible')){
                                TweenMax.to(".logo-wrapper", .5, {opacity:1, display: "block"}); 
                            }

                        });


                        //Add Listener mouseWheel to panZoom
                        
                        var firefox  = navigator.userAgent.indexOf('Firefox') > -1
                        
                        if(!firefox){
                            self.panZoom.parent().on('mousewheel.focal', function(e) {
                                e.preventDefault();
                                var delta = e.delta || e.originalEvent.wheelDelta;
                                var zoomOut = delta ? delta < 0 : e.originalEvent.deltaY > 0;
                                self.panZoom.panzoom('zoom', zoomOut, {
                                    increment: 0.5,
                                    animate: true,
                                    focal: e
                                });
                            });    
                        }
                        

                        //Add debounce function to panZoom onChange with transformation
                        self.panZoom.on('panzoomchange', _.debounce(function(event, panzoom, transform) {
                            this.style.display = 'none';
                            this.offsetHeight;
                            this.style.display = '';
                        },
                            300,
                            {
                                'leading': false,
                                'trailing': true
                            }));
                    });
                });
                self.timeline.to('#home', 0.8, { opacity: 1, delay: .15 });
                // //Set and change wines-map property
                // self.timeline.set('#wines-map', { display: 'block', opacity: 0 });
                // self.timeline.to('#wines-map', 0.5, { opacity: 1 });
            };

            //Zoom out the map
            Home.prototype.zoomOut = function() {
                if (!self.panZoom) return;

                var focal = {
                    clientX: $('#map-svg').innerWidth() * 0.5,
                    clientY: $('#map-svg').innerHeight() * 0.5
                }

                self.panZoom.panzoom('zoom', true, {
                    increment: 0.5,
                    animate: true,
                    focal: focal
                });

                
            };

            //Zoom in the map
            Home.prototype.zoomIn = function() {
                if (!self.panZoom) return;

                var focal = {
                    clientX: $('#map-svg').innerWidth() * 0.5,
                    clientY: $('#map-svg').innerHeight() * 0.5
                }

                self.panZoom.panzoom('zoom', false, {
                    increment: 0.5,
                    animate: true,
                    focal: focal
                });
            };

            // Show "more info" panel
            Home.prototype.moreInfo = function() {
                TweenMax.to('#navbox-map', .5, { opacity: 0, display: 'none' });
                TweenMax.to('#moreinfo', .5, { display: 'block', opacity: 1 });
            }

            // Close "more info" panel
            Home.prototype.lessInfo = function() {
                TweenMax.to('#moreinfo', .5, { opacity: 0, display: 'none' })
                TweenMax.to('#navbox-map', .5, { display: 'block', opacity: 1 });
                    
            }
            
            // Show "contact info" panel
            Home.prototype.contactInfo = function() {
                TweenMax.to('#navbox-map', .5, { opacity: 0, display: 'none' })
                TweenMax.to('#contactinfo', .5, { display: 'block', opacity: 1 });
            }
            
            // Close "more info" panel
            Home.prototype.lessContact = function() {
                TweenMax.to('#contactinfo', .5, { opacity: 0, display: 'none' })
                TweenMax.to('#navbox-map', .5, { display: 'block', opacity: 1 });
                    
            }

            return Home;
        })();

        Controllers.Home = Home;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));